/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import Slider from "react-slick"
import HomeLogo from "../../components/HomeLogo"
import Pagination from "../../components/Pagination"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../../layouts/PageWrapper"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

class HSSEQPage extends Component {
  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      year: 0,
      photoIndex: 0,
      isOpen: false,
      currentPage: props.data.allMarkdownRemark.group
        ? props.data.allMarkdownRemark.group.length
        : 0,
    }
  }
  render() {
    const { data } = this.props
    const { group: years } = data.allMarkdownRemark
    const { currentPage } = this.state
    const { photoIndex, isOpen } = this.state

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <PageWrapper>
        <section className="hero is-fullheight">
          <Helmet>
            <title> HSSEQ - E.A. Technique</title>
          </Helmet>
          <section className="hero is-small">
            <div className="hero-body">
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      HSSEQ
                    </h1>
                    <h4 className="is-size-4 has-text-weight-bold">BULLETIN</h4>
                    <Link to={`/hsseq/policy`}>
                      <h4 className="is-size-7 has-text-weight-bold blue">
                        POLICY
                      </h4>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <Pagination
                id="hsseq_pagination"
                currentPage={currentPage}
                total={years.length}
                pageCount={5}
                style={{ margin: `auto`, width: `50%`, marginBottom: `200px` }}
                pageValues={years.map((year) => year.fieldValue)}
                handlePageChange={this.handlePageChange}
              />
              <div className="columns is-multiline">
                <Tabs>
                  <TabList className="react-tabs__tab-list-primary">
                    {years[
                      currentPage - 1
                    ].edges[0].node.frontmatter.quarters.map(
                      (quarter, index) => (
                        <Tab className="react-tabs__tab has-text-centered">
                          {quarter.quarter_number}
                        </Tab>
                      )
                    )}
                    {/* <Tab className="react-tabs__tab has-text-centered">Q1</Tab>
                  <Tab className="react-tabs__tab has-text-centered">Q2</Tab>
                  <Tab className="react-tabs__tab has-text-centered">Q3</Tab>
                  <Tab className="react-tabs__tab has-text-centered">Q4</Tab> */}
                  </TabList>

                  {years[
                    currentPage - 1
                  ].edges[0].node.frontmatter.quarters.map((quarter, index) => (
                    <TabPanel key={index}>
                      <div className="columns is-multiline">
                        {quarter.quarter_images.map((image, index) => (
                          <div className="column is-3">
                            <img
                              className="pointer hsseq"
                              src={image}
                              onClick={() =>
                                this.setState({
                                  isOpen: true,
                                  photoIndex: index,
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>

                      {isOpen && (
                        <Lightbox
                          mainSrc={quarter.quarter_images[photoIndex]}
                          nextSrc={
                            quarter.quarter_images[
                              (photoIndex + 1) % quarter.quarter_images.length
                            ]
                          }
                          prevSrc={
                            quarter.quarter_images[
                              (photoIndex + quarter.quarter_images.length - 1) %
                                quarter.quarter_images.length
                            ]
                          }
                          onCloseRequest={() =>
                            this.setState({ isOpen: false })
                          }
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex:
                                (photoIndex +
                                  quarter.quarter_images.length -
                                  1) %
                                quarter.quarter_images.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex:
                                (photoIndex + 1) %
                                quarter.quarter_images.length,
                            })
                          }
                        />
                      )}
                    </TabPanel>
                  ))}
                  {/* <TabPanel>
                  <div className="columns is-multiline">
                    {years.map((year, index) =>
                      <div className="column is-3">
                        <img className="pointer hsseq" src={years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images[0]} onClick={() => this.setState({ isOpen: true, photoIndex: index })} />
                      </div>
                    )}
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={years.edges.node.frontmatter.quarters[this.state.quarter].quarter_images[photoIndex]}
                      nextSrc={years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images[(photoIndex + 1) % years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images.length]}
                      prevSrc={years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images[(photoIndex + years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images.length - 1) % years.edges[0].node.frontmatter.quarters[this.state.quarter].quarter_images.length]}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + years.edges[0].node.frontmatter.quarters[this.state.quarters].quarter_images.length - 1) % years.edges[0].node.frontmatter.quarters[this.state.quarters].quarter_images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % years.edges[0].node.frontmatter.quarters[this.state.quarters].quarter_images.length,
                        })
                      }
                    />
                  )}
                </TabPanel> */}
                </Tabs>
              </div>
            </div>
          </section>
        </section>
      </PageWrapper>
    )
  }
}

HSSEQPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default HSSEQPage

export const HSSEQPageQuery = graphql`
  query HSSEQPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "hsseq-page" } } }
    ) {
      group(field: frontmatter___year) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              quarters {
                quarter_number
                quarter_images
              }
            }
          }
        }
      }
    }
  }
`
